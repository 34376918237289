import React, { Fragment } from 'react'
import { Col } from 'react-bootstrap'
import ShipmentLogsContainer from 'components/common/logs/ShipmentLogsContainer'
import { getAmountWithCurrencySymbol } from '../../helpers/FormatCurrency'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClock,
  faMoneyBill,
  faPhone,
  faShippingFast,
  faShop,
} from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import { useI18next } from 'gatsby-plugin-react-i18next'

const TrackingPage = ({ t, shipment }) => {
  const isRtl = document.body.style.direction === 'rtl'
  const i18n = useI18next()

  return (
    <Col className="mb-2">
      <h4 className={'lh-2 text-dark'}>
        <FontAwesomeIcon icon={faShop} className={isRtl ? 'ms-2' : 'me-2'} />
        {shipment.order.store_name}
      </h4>
      <h4 className="lh-2 text-dark">
        <FontAwesomeIcon icon={faPhone} className={isRtl ? 'ms-2' : 'me-2'} />
        {[
          shipment.order.organization?.address?.phone1,
          shipment.order.organization?.address?.phone2,
        ]
          .filter((a) => a)
          .map((phone, i) => (
            <Fragment key={i}>
              <a key={i} dir="ltr" href={`tel:${phone}`}>
                {phone}
              </a>
              {i > 1 && <span> - </span>}
            </Fragment>
          ))}
      </h4>
      <h4 className="lh-2 text-dark">
        <FontAwesomeIcon
          icon={faShippingFast}
          className={isRtl ? 'ms-2' : 'me-2'}
        />
        {t('Will be delivered by {{carrier}}', {
          carrier: shipment.tracking_numbers?.[0]?.carrier.name,
          ns: 'order-details-logs',
        })}
      </h4>
      <h4 className="lh-2 text-dark">
        <FontAwesomeIcon icon={faClock} className={isRtl ? 'ms-2' : 'me-2'} />
        {t('Expected delivery date from {{date}} to {{date2}}', {
          date: dayjs(
            shipment.process_expectations_dates.expected_delivery_date_from
          ).format('DD/MM/YYYY'),
          date2: dayjs(
            shipment.process_expectations_dates.expected_delivery_date_to
          ).format('DD/MM/YYYY'),
          ns: 'order-details-logs',
        })}
      </h4>
      {shipment.cod_transaction ? (
        <h4 className="lh-2 text-dark">
          <FontAwesomeIcon
            icon={faMoneyBill}
            className={isRtl ? 'ms-2' : 'me-2'}
          />
          {t('Payment on delivery of {{amount}}', {
            amount: getAmountWithCurrencySymbol(
              shipment.cod_transaction.amount,
              shipment.cod_transaction.currency
            ),
            ns: 'order-details-logs',
          })}
        </h4>
      ) : (
        <></>
      )}

      <h5 className="py-3">
        {t(
          'Here is detailed information about your shipment and its delivery status'
        )}
      </h5>
      <div className="custom-scroller" style={{ overflowX: 'auto' }}>
        <table className="table table-bordered fs-0 border mb-0">
          <thead className="bg-table-head-gradient text-dark">
            <tr>
              <th className="text-nowrap fs--1 p-3">{t('Product')}</th>
              <th className="align-middle text-center text-nowrap fs--1 py-3 px-4">
                {t('Quantity')}
              </th>
            </tr>
          </thead>
          <tbody style={{ borderTop: 'none' }}>
            {shipment?.details?.map(({ product, qte }, index) => {
              const mediaUrl = product.is_variation
                ? product.variation_media?.original_url
                : product.media[0].original_url

              return (
                <tr key={index}>
                  <td className="align-middle">
                    <div className="d-flex align-items-center">
                      <div className="variation-image-container md">
                        <div className="d-block h-sm-100">
                          <div
                            style={{
                              backgroundImage: `url(${mediaUrl})`,
                              backgroundSize: 'contain',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              height: '64px',
                              width: '64px',
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="d-flex flex-column text-truncate ps-1 pe-4">
                        <h5 className="text-800 mb-0 text-wrap fs-0 pb-2">
                          {product.name_translations[i18n.language] ??
                            product.name}
                        </h5>
                        {product.is_variation && (
                          <div className="d-flex text-nowrap mt-1">
                            <div className="d-flex flex-column text-nowrap">
                              {product.options.map((row, index) => (
                                <div className="d-flex" key={index}>
                                  <span className="fs--1 text-700 me-1 text-capitalize">
                                    {row.name}:{' '}
                                  </span>
                                  <span className="fs--1 ">{row.value}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="align-middle fw-bold text-center">{qte}</td>
                </tr>
              )
            })}
          </tbody>
          {/* <thead className="bg-table-head-gradient text-white">
            <tr>
              <th className="text-nowrap fs--1 p-3">{t("COD Amount")}</th>
              <th className="align-middle text-center text-nowrap fs--1 py-3 px-4">
                {t("Accepted Method")}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="align-middle fw-bold text-center">
                {shipment.cod_transaction
                  ? getAmountWithCurrencySymbol(
                      shipment.cod_transaction.amount,
                      shipment.cod_transaction.currency
                    )
                  : "NONCOD"}
              </td>
              <td className="align-middle fw-bold text-center">
                {t("Cash Only")}
              </td>
            </tr>
          </tbody> */}
        </table>
      </div>

      <ShipmentLogsContainer shipment={shipment} />
      {/* <Logs logs={tracking_logs} /> */}
    </Col>
  )
}

export default TrackingPage
