import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import Logs from './Logs'
import { get_customer_outreach_formatted_log } from './get_customer_outreach_formatted_log'
import { get_formatted_log } from './get_formatted_log'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import noteSVG from 'assets/img/svgs/contact-logs-icons/note.svg'

function ShipmentLogsContainer({ shipment }) {
  const { t } = useTranslation('order-details-logs')
  const { t: contact_t } = useTranslation('order-details-logs')

  const get_logs = () => {
    let res = []

    const shipment_details = {
      // shipment_id: getFirstPartOfUUID(shipment.uuid),
      // supplier_organization_name: shipment.fulfiller_organization.org_username,
      currency: shipment.details[0]?.currency,
    }

    res.push(
      ...(shipment?.logs?.map((log) => ({ ...log, ...shipment_details })) ?? [])
    )
    res.push(
      ...shipment.tracking_numbers
        .map((tracking_number) =>
          tracking_number.logs.map((log) => ({
            ...log,
            ...shipment_details,
            created_at: log.external_timestamp
              ? log.external_timestamp
              : log.created_at,
            tracking_number,
          }))
        )
        .flat()
    )

    const formatted_delivery_logs = res.map((log) => ({
      ...log,
      ...get_formatted_log(log, t),
    }))

    const formatted_customer_outreach_logs = [
      ...(shipment?.customer_outreaches ?? []),
    ]?.map((log) => ({
      ...log,
      ...get_customer_outreach_formatted_log(log, contact_t),
    }))

    const formatted_notes_logs = [...(shipment.contact_task?.notes ?? [])]
      .filter((item) => item)
      .map((note) => ({
        ...note,
        icon: noteSVG,
        title: contact_t('Confirmation agent added a note'),
        description: note.body,
      }))

    return [
      ...formatted_delivery_logs,
      ...formatted_customer_outreach_logs,
      ...formatted_notes_logs,
    ]
      .filter((log) => log.title)
      .sort((a, b) => dayjs(a.created_at) - dayjs(b.created_at))
  }

  return (
    <Logs
      sorted_formatted_logs={get_logs().reverse()}
      use_accordion={true}
      title={t('Shipment log')}
      // subtitle={t(
      //   "You can monitor all the changes that happened to this shipment from here."
      // )}
    />
  )
}

ShipmentLogsContainer.propTypes = {
  shipment: PropTypes.object.isRequired,
}

export default ShipmentLogsContainer
