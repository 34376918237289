import React, { useEffect, useState } from 'react'

import CardLoader from '../components/common/CardLoader'
import ShipmentTrackingPage from '../components/common/ShipmentTrackingPage'
import { Card, Container } from 'react-bootstrap'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'

import 'react-toastify/dist/ReactToastify.min.css'
import NotFoundPage from './404'
import { graphql } from 'gatsby'
import dayjs from 'dayjs'
import 'dayjs/locale/ar'
import Seo from '../components/seo'
import shipperExpressLogo from 'images/shipper-express-logo.svg'
import EnterTrackingInfoPage from '../components/common/EnterTrackingInfoPage'
import partners from 'images/media/og-photo.png'

function Page() {
  const { t } = useTranslation(['tracking', 'order-details-logs'])
  const { i18n } = useI18next()

  useEffect(() => {
    console.log(i18n.resolvedLanguage)
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    setShipment_uuid(urlParams.get('id'))
    if (i18n.resolvedLanguage === 'ar') {
      document.body.style.direction = 'rtl'
      document.body.style.fontFamily = 'Helvetica Neue LT Arabic'
    }
    dayjs.locale(i18n.resolvedLanguage)
  }, [i18n.resolvedLanguage])

  const [shipment_uuid, setShipment_uuid] = useState(null)
  const [shipment, setShipment] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showTracking, setShowTracking] = useState(false)

  const fetchData = async (shipment_uuid) => {
    try {
      const REVIEW_URL = new URL(
        `/api/shipment-tracking/${shipment_uuid}`,
        process.env.GATSBY_APP_HOST
      ).toString()

      setLoading(true)
      const res = await fetch(REVIEW_URL)
      const content = await res.json()
      setShipment(content)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      console.log('hhhhhh')
    }
  }

  useEffect(() => {
    if (shipment_uuid) {
      setShowTracking(false)
      fetchData(shipment_uuid)
    } else {
      setShowTracking(true)
      setLoading(false)
    }
  }, [shipment_uuid])

  // const tracking_logs = shipment?.tracking_numbers?.length
  //   ? shipment?.tracking_numbers[0]?.logs
  //   : null;

  // if (loading) {
  //   return (
  //     <>
  //       <Seo
  //         title={`${t("Shipper")} | ${t("Track your shipment")}`}
  //         description={t(
  //           "Easily track your shipments and stay informed about their delivery status."
  //         )}
  //         keywords={t(
  //           "shipment tracking, shipment, shipper express, order tracking"
  //         )}
  //         // image={partners}
  //       />
  //       <FullPageLoader />
  //     </>
  //   );
  // }

  // if (showTracking) {
  //   return (
  //     <>
  //       <Seo
  //         title={`${t("Shipper")} | ${t("Track your shipment")}`}
  //         description={t(
  //           "Easily track your shipments and stay informed about their delivery status."
  //         )}
  //         keywords={t(
  //           "shipment tracking, shipment, shipper express, order tracking"
  //         )}
  //         // image={partners}
  //       />
  //       <ShipmentTrackingPage />;
  //     </>
  //   );
  // }

  if (!shipment && !showTracking && !loading) {
    return (
      <>
        <Seo
          title={`${t('Shipper')} | ${t('Track your shipment')}`}
          description={t(
            'Easily track your shipments and stay informed about their delivery status.'
          )}
          keywords={t(
            'shipment tracking, shipment, shipper express, order tracking'
          )}
          image={partners}
        />
        <NotFoundPage />;
      </>
    )
  }

  if (shipment?.tracking_numbers?.length === 0) {
    return (
      <>
        <Seo
          title={`${t('Shipper')} | ${t('Track your shipment')}`}
          description={t(
            'Easily track your shipments and stay informed about their delivery status.'
          )}
          keywords={t(
            'shipment tracking, shipment, shipper express, order tracking'
          )}
          image={partners}
        />
        <NotFoundPage />;
      </>
    )
  }

  return (
    <>
      <Seo
        title={`${t('Shipper')} | ${t('Track your shipment')}`}
        description={t(
          'Easily track your shipments and stay informed about their delivery status.'
        )}
        keywords={t(
          'shipment tracking, shipment, shipper express, order tracking'
        )}
        image={partners}
      />
      <Container>
        <div className="g-0 min-vh-100 py-2">
          <div className={'text-center py-2 mt-3'}>
            <img
              src={shipperExpressLogo}
              style={{ width: '220px', maxWidth: '100%' }}
              alt={'Shipper Express Logo'}
            />
          </div>
          <Card>
            <Card.Header className="py-3 text-center bg-card-gradient text-white titlefont">
              <h4 className="pt-1 mb-0">{t('Order tracking')}</h4>
            </Card.Header>
            <Card.Body>
              {loading ? (
                <CardLoader />
              ) : showTracking ? (
                <EnterTrackingInfoPage
                  t={t}
                  setShipment_uuid={setShipment_uuid}
                />
              ) : (
                <ShipmentTrackingPage
                  t={t}
                  shipment={shipment}
                  // tracking_logs={tracking_logs}
                />
              )}
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["tracking", "order-details-logs"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Page
