import React from 'react'
import Lottie from 'react-lottie'
import lightModeIcon from 'assets/json/Light Green.json'
// import darkModeIcon from "assets/json/Shipper_Loading_Loop_Dark_Mode.json"

const LogoIcon = ({ height = '70px' }) => {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: lightModeIcon,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <Lottie
      isClickToPauseDisabled={true}
      options={lottieOptions}
      height={height}
      width={height}
      style={{
        cursor: 'default',
      }}
    />
  )
}

export default LogoIcon
